<template>
	<div>
		<b-card title="NPWP Request List">
			<b-row>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Status:</label>
						<select 
							class="form-control"
							v-model="link"
							@change="getData"
						>
							<option value="pending">Pending</option>
							<option value="ready">Ready to submit</option>
							<option value="done">Done</option>
						</select>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Name:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: Nama"
							v-model="filter.name" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Phone:</label>
						<input 
							type="number" 
							class="form-control"
							placeholder="Search: Phone"
							v-model="filter.phone_number" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Email:</label>
						<input 
							type="email" 
							class="form-control"
							placeholder="Search: Email"
							v-model="filter.email" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Waktu Pengajuan:</label>
						<flat-pickr
							v-model="filter.created_at"
							class="form-control"
							placeholder="Search: Waktu Pengajuan"
							:config="{ 
								mode: 'range',
								locale: {
									rangeSeparator: ' - ',
								},
							}"
						/>
					</div>
				</b-col>
			</b-row>
			<b-col cols="12" class="p-0 mt-2">
				<button 
					:disabled="!filter.name" 
					@click="filter.name = ''" 
					class="btn btn-outline-secondary mr-2"
				>
					Reset
				</button>
			</b-col>
			<div class="border__dash"></div>
			<b-table 
				striped 
				hover 
				:items="npwp" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>

				<template v-slot:cell(no)="{ index }">
					{{ index + 1 }}
				</template>

				<template v-slot:cell(status)="{ item }">
					<b-badge
						variant="light-secondary"
						v-if="item.status == 'pending'"
					>
						Pending
					</b-badge>
					<b-badge
						variant="light-warning"
						v-if="item.status == 'ready'"
					>
						Ready to submit
					</b-badge>
					<b-badge
						variant="light-primary"
						v-if="item.status == 'done'"
					>
						Done
					</b-badge>
				</template>

				<template v-slot:cell(waktu_pengajuan)="{ item }">
					{{ item.created_at }}
				</template>
				
				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<b-badge 
							variant="primary"
							class="badge-glow d-flex align-items-center"
							@click="detailItem(item)"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Detail Data'"
							v-if="checkPermission('detail efin request')"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
							/>
							Detail
						</b-badge>
						
						<b-badge 
							variant="danger"
							class="badge-glow d-flex align-items-center"
							v-if="item.status == 'ready'"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							@click="SetToPending(item.uuid)"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="ArrowUpIcon"
								size="16"
							/>
							Set to Pending
						</b-badge>

					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>

			<b-modal
				id="form-npwp-modal" 
				centered
				title="Detail Request"
				size="lg"
				no-close-on-backdrop
				hide-footer
			>
				<div class="text-center text-secondary my-2" v-if="isLoading">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>

				<ValidationObserver v-else>
					<div class="form-row">
						<div class="form-group col-md-6">
							<label>Name :</label>
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Name" 
									v-model="formPayload.name"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Email :</label>
							<validation-provider
								name="email"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Email" 
									v-model="formPayload.email"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.email"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>			
						<div class="form-group col-md-6">
							<label>Phone :</label>
							<validation-provider
								name="phone_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="number"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Phone" 
									v-model="formPayload.phone_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.phone_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Gender :</label>
							<validation-provider
								name="email"
								rules="required"
								v-slot="{ errors }"
							>
								<div class="demo-inline-spacing" style="margin-top:8px;">
									<div class="custom-control custom-radio mt-0">
										<input 
											v-model="formPayload.gender"
											name="inlineRadioOptions" 
											class="custom-control-input"
											:state="errors.length > 0 ? false:null" 
											type="radio" 
											id="radio1" 
											value="male"
											:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
										>
										<label 
											class="custom-control-label" 
											for="radio1"
										>
											Pria
										</label>
									</div>
									<div class="custom-control custom-radio mt-0">
										<input 
											v-model="formPayload.gender"
											name="inlineRadioOptions" 
											class="custom-control-input" 
											:state="errors.length > 0 ? false:null"
											type="radio" 
											id="radio2"
											value="female"
											:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
										>
										<label 
											class="custom-control-label" 
											for="radio2"
										>
											Wanita
										</label>
									</div>
								</div>
								<small
									v-for="(validation, index) in validations.gender"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>NIK :</label>
							<validation-provider
								name="nik"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="number"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="NIK" 
									v-model="formPayload.nik"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.nik"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>No. KK :</label>
							<validation-provider
								name="family_card_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="number"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KK" 
									v-model="formPayload.family_card_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.family_card_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Birthdate :</label>
							<validation-provider
								name="birthdate"
								rules="required"
								v-slot="{ errors }"
							>
								<flat-pickr
									v-model="formPayload.birth_date"
									:state="errors.length > 0 ? false:null"
									class="form-control"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								/>
								<small
									v-for="(validation, index) in validations.birth_date"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Birthplace :</label>
							<validation-provider
								name="birthplace"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Birthplace" 
									v-model="formPayload.birth_place"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.birth_place"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Status Pernikahan :</label>
							<validation-provider
								name="marital_status"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<select 
									class="form-control" 
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-model="formPayload.marital_status"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
									<option value="">-- Pilih --</option>
									<option value="married">KAWIN</option>
									<option value="not_married">TIDAK KAWIN</option>
									<option value="divorced">CERAI HIDUP</option>
									<option value="widowed">CERAI MATI</option>
								</select>
								<small
									v-for="(validation, index) in validations.marital_status"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Country :</label>
							<validation-provider
								name="country_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<v-select
									v-model="formPayload.country_code"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="name"
									placeholder="-- Pilih Country --"
									:options="countries"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
									:reduce="country => country.code"
								/>
								<small
									v-for="(validation, index) in validations.country_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Passpor Number :</label>
							<validation-provider
								name="passpor_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Passpor Number" 
									v-model="formPayload.passpor_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.passpor_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Kitas Number :</label>
							<validation-provider
								name="kitas_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Kitas Number" 
									v-model="formPayload.kitas_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.kitas_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Taxpayer Center Status :</label>
							<validation-provider
								name="taxpayer_center_status"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<select 
									class="form-control" 
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-model="formPayload.taxpayer_center_status"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
									<option value="">-- Pilih --</option>
									<option value="pusat">Pusat</option>
									<option value="cabang">Cabang</option>
								</select>
								<small
									v-for="(validation, index) in validations.taxpayer_center_status"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Taxpayer Center NPWP :</label>
							<validation-provider
								name="taxpayer_center_npwp"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Taxpayer Center NPWP" 
									v-model="formPayload.taxpayer_center_npwp"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.taxpayer_center_npwp"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Penghasilan :</label>
							<validation-provider
								name="income_range_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<select 
									class="form-control" 
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-model="formPayload.income_range_code"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
									<option value="">-- Pilih --</option>
									<option value="1">Kurang dari 4.5 JT/Bulan</option>
									<option value="2">4.5-9.9 JT/Bulan</option>
									<option value="3">10-14.9 JT/Bulan</option>
									<option value="4">15-19.9 JT/Bulan</option>
									<option value="5">Diatas 20 JT/Bulan</option>
								</select>
								<small
									v-for="(validation, index) in validations.income_range_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<!-- KLU INFORMATION -->
						<div class="form-group col-md-12">
						  <hr>
						  <h4>KLU Information</h4>
						</div>
						<div class="form-group col-md-6">
							<label>KLU Type :</label>
							<validation-provider
								name="klu_type"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<select 
									class="form-control" 
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-model="formPayload.klu_type"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
									@change="changeKluType($event)"
								>
									<option value="">-- Pilih KLU Type --</option>
									<option value="klu1">KLU 1</option>
									<option value="klu2">KLU 2</option>
									<option value="klu3">KLU 3</option>
									<option value="klu4">KLU 4</option>
								</select>
								<small
									v-for="(validation, index) in validations.klu_type"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KLU Code :</label>
							<validation-provider
								name="klu_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KLU Code" 
									v-model="formPayload.klu_code"
									disabled
								>
								<small
									v-for="(validation, index) in validations.klu_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-12">
							<label>KLU Name :</label>
							<validation-provider
								name="klu_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<v-select
									v-model="formPayload.klu_code"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="name"
									placeholder="-- Pilih KLU --"
									:options="kluOptions"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
									:reduce="option => option.klu_code"
								/>
								<small
									v-for="(validation, index) in validations.klu_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KLU Pembukuan :</label>
							<validation-provider
								name="klu_pembukuan"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KLU Pembukuan" 
									v-model="formPayload.klu_pembukuan"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.klu_pembukuan"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KLU Pegawai :</label>
							<validation-provider
								name="klu_have_pegawai"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KLU Pegawai" 
									v-model="formPayload.klu_have_pegawai"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.klu_have_pegawai"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KLU Thn Buku Awal :</label>
							<validation-provider
								name="klu_thn_buku_awal"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KLU Thn Buku Awal" 
									v-model="formPayload.klu_thn_buku_awal"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.klu_thn_buku_awal"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KLU Thn Buku Akhir :</label>
							<validation-provider
								name="klu_thn_buku_akhir"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KLU Thn Buku Akhir" 
									v-model="formPayload.klu_thn_buku_akhir"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.klu_thn_buku_akhir"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<!-- KLU INFORMATION end  -->

						<!-- DOMISILI ADDRESS -->
						<div class="form-group col-md-12">
						 	<hr>
						 	<h4>Domisili Address</h4>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Village :</label>
							<validation-provider
								name="domisili_village"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Village" 
									v-model="formPayload.domisili_village"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_village"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
							<div class="dropdown-container">
								<ul v-if="searchResultsDomisili.length > 0" class="list-group mt-12">
							      	<li 
								        v-for="(result, index) in searchResultsDomisili" 
								        :key="index" 
								        class="list-group-item"
								        @click="selectVillage('domisili', result)"
							      	>
							        	{{ result.village }} - {{ result.subdistrict }} - {{ result.city }}
							      	</li>
							    </ul>
							</div>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Sub-District :</label>
							<validation-provider
								name="domisili_subdistrict"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili District" 
									v-model="formPayload.domisili_subdistrict"
									disabled
								>
								<small
									v-for="(validation, index) in validations.domisili_subdistrict"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili City :</label>
							<validation-provider
								name="domisili_city"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili City" 
									v-model="formPayload.domisili_city"
									disabled
								>
								<small
									v-for="(validation, index) in validations.domisili_city"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Province :</label>
							<validation-provider
								name="domisili_province"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili City" 
									v-model="formPayload.domisili_province"
									disabled
								>
								<small
									v-for="(validation, index) in validations.domisili_province"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Region Code :</label>
							<validation-provider
								name="domisili_region_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Region Code" 
									v-model="formPayload.domisili_region_code"
									disabled
								>
								<small
									v-for="(validation, index) in validations.domisili_region_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Postal Code :</label>
							<validation-provider
								name="domisili_postal_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Postal Code" 
									v-model="formPayload.domisili_postal_code"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_postal_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Blok :</label>
							<validation-provider
								name="domisili_blok"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Blok" 
									v-model="formPayload.domisili_blok"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_blok"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Number :</label>
							<validation-provider
								name="domisili_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Number" 
									v-model="formPayload.domisili_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili RT :</label>
							<validation-provider
								name="domisili_rt"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili RT" 
									v-model="formPayload.domisili_rt"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_rt"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili RW :</label>
							<validation-provider
								name="domisili_rw"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili RW" 
									v-model="formPayload.domisili_rw"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_rw"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Address :</label>
							<validation-provider
								name="domisili_street"
								rules="required"
								v-slot="{ errors }"
							>
								<textarea 
									class="form-control"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Address" 
									v-model="formPayload.domisili_street"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								/>
								<small
									v-for="(validation, index) in validations.domisili_street"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Faksimili :</label>
							<validation-provider
								name="domisili_faksimili_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Faksimili" 
									v-model="formPayload.domisili_faksimili_code"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_faksimili_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domilisi Phone Number :</label>
							<validation-provider
								name="domisili_phone_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domilisi Phone Number" 
									v-model="formPayload.domisili_phone_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_phone_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Domisili Telp Number :</label>
							<validation-provider
								name="domisili_telp_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Domisili Telp Number" 
									v-model="formPayload.domisili_telp_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.domisili_telp_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<!-- DOMISILI ADDRESS end -->

						<!-- KTP ADDRESS -->
						<div class="form-group col-md-12">
						 	<hr>
						 	<h4>KTP Address</h4>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Village :</label>
							<validation-provider
								name="ktp_village"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Village" 
									v-model="formPayload.ktp_village"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_village"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
							<div class="dropdown-container">
								<ul v-if="searchResultsKtp.length > 0" class="list-group mt-12">
							      	<li 
								        v-for="(result, index) in searchResultsKtp" 
								        :key="index" 
								        class="list-group-item full-width-item"
								        @click="selectVillage('ktp', result)"
							      	>
							        	{{ result.village }} - {{ result.subdistrict }} - {{ result.city }}
							      	</li>
							    </ul>
							</div>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Sub-District :</label>
							<validation-provider
								name="ktp_subdistrict"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP District" 
									v-model="formPayload.ktp_subdistrict"
									disabled
								>
								<small
									v-for="(validation, index) in validations.ktp_subdistrict"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP City :</label>
							<validation-provider
								name="ktp_city"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP City" 
									v-model="formPayload.ktp_city"
									disabled
								>
								<small
									v-for="(validation, index) in validations.ktp_city"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Province :</label>
							<validation-provider
								name="ktp_province"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Province" 
									v-model="formPayload.ktp_province"
									disabled
								>
								<small
									v-for="(validation, index) in validations.ktp_province"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Region Code :</label>
							<validation-provider
								name="ktp_region_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Region Code" 
									v-model="formPayload.ktp_region_code"
									disabled
								>
								<small
									v-for="(validation, index) in validations.ktp_region_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Postal Code :</label>
							<validation-provider
								name="ktp_postal_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Postal Code" 
									v-model="formPayload.ktp_postal_code"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_postal_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Blok :</label>
							<validation-provider
								name="ktp_blok"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Blok" 
									v-model="formPayload.ktp_blok"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_blok"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Number :</label>
							<validation-provider
								name="ktp_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Number" 
									v-model="formPayload.ktp_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP RT :</label>
							<validation-provider
								name="ktp_rt"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP RT" 
									v-model="formPayload.ktp_rt"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_rt"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP RW :</label>
							<validation-provider
								name="ktp_rw"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP RW" 
									v-model="formPayload.ktp_rw"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_rw"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Address :</label>
							<validation-provider
								name="ktp_street"
								rules="required"
								v-slot="{ errors }"
							>
								<textarea 
									class="form-control"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Address" 
									v-model="formPayload.ktp_street"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								/>
								<small
									v-for="(validation, index) in validations.ktp_street"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Faksimili :</label>
							<validation-provider
								name="ktp_faksimili_code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Faksimili" 
									v-model="formPayload.ktp_faksimili_code"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_faksimili_code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Phone Number :</label>
							<validation-provider
								name="ktp_phone_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Phone Number" 
									v-model="formPayload.ktp_phone_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_phone_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>KTP Telp Number :</label>
							<validation-provider
								name="ktp_telp_number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="KTP Telp Number" 
									v-model="formPayload.ktp_telp_number"
									:disabled="formPayload.status == 'ready' || formPayload.status == 'done'"
								>
								<small
									v-for="(validation, index) in validations.ktp_telp_number"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<!-- KTP ADDRESS end -->

						<div class="form-group col-md-12">
						 	<hr>
						 	<h4>File</h4>
						</div>
						<div class="form-group col-md-6">
							<label for="">Foto KTP : </label><br>
							<validation-provider
								name="fileKtp"
								rules="required"
							>

							<template v-if="formPayload.status == 'pending'">
								<b-form-file
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									@change="changePhotoKtp($event)"
								/>
									<small>Current Foto KTP : </small><br>
							</template>
									<img
										v-if="formPayload.fileKtp !== null"
										:src="formPayload.fileKtp"
										style="max-width: 100%;"
									>
								<small
									v-for="(validation, index) in validations.fileKtp"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label for="">Foto Pisah Harta : </label><br>
							<validation-provider
								name="filePisahHarta"
								rules="required"
							>

							<template v-if="formPayload.status == 'pending'">
								<b-form-file
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									@change="changePhotoPisahHarta($event)"
								/>
									<small>Current Foto Pisah Harta : </small><br>
							</template>
									<img
										v-if="formPayload.filePisahHarta !== null"
										:src="formPayload.filePisahHarta"
										style="max-width: 100%;"
									>
								<small
									v-for="(validation, index) in validations.filePisahHarta"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label for="">Foto Akta Kawin : </label><br>
							<validation-provider
								name="fileAktaKawin"
								rules="required"
							>

							<template v-if="formPayload.status == 'pending'">
								<b-form-file
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									@change="changePhotoAktaKawin($event)"
								/>
									<small>Current Foto Akta Nikah : </small><br>
							</template>
									<img
										v-if="formPayload.fileAktaKawin !== null"
										:src="formPayload.fileAktaKawin"
										style="max-width: 100%;"
									>
								<small
									v-for="(validation, index) in validations.fileAktaKawin"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label for="">Foto NPWP Suami : </label><br>
							<validation-provider
								name="fileNpwpSuami"
								rules="required"
							>

							<template v-if="formPayload.status == 'pending'">
								<b-form-file
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									@change="changePhotoNpwpSuami($event)"
								/>
									<small>Current Foto NPWP Suami : </small><br>
							</template>
									<img
										v-if="formPayload.fileNpwpSuami !== null"
										:src="formPayload.fileNpwpSuami"
										style="max-width: 100%;"
									>
								<small
									v-for="(validation, index) in validations.fileNpwpSuami"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label for="">Foto Memilih Terpisah : </label><br>
							<validation-provider
								name="fileMemilihTerpisah"
								rules="required"
							>

							<template v-if="formPayload.status == 'pending'">
								<b-form-file
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									@change="changePhotoMemilihTerpisah($event)"
								/>
									<small>Current Foto Memilih Terpisah : </small><br>
							</template>
									<img
										v-if="formPayload.fileMemilihTerpisah !== null"
										:src="formPayload.fileMemilihTerpisah"
										style="max-width: 100%;"
									>
								<small
									v-for="(validation, index) in validations.fileMemilihTerpisah"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label for="">Foto Kitas : </label><br>
							<validation-provider
								name="fileKitas"
								rules="required"
							>

							<template v-if="formPayload.status == 'pending'">
								<b-form-file
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									@change="changePhotoKitas($event)"
								/>
									<small>Current Foto Memilih Terpisah : </small><br>
							</template>
									<img
										v-if="formPayload.fileKitas !== null"
										:src="formPayload.fileKitas"
										style="max-width: 100%;"
									>
								<small
									v-for="(validation, index) in validations.fileKitas"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</div>
					<template v-if="isLoading">
						<b-button 
							variant="primary"
						>
							<b-spinner
								variant="secondary"
								disabled
							/>
						</b-button>
					</template>
					<template v-else>
						<b-button
							v-if="formPayload.status == 'pending' && checkPermission('update npwp request')"
							variant="success"
							class="mr-1"
							@click="updateForm()"
						>
							Simpan
						</b-button>
						<b-button 
							v-if="formPayload.status == 'pending' && checkPermission('update npwp request')"
							variant="primary" 
							@click="updateToReady()" 
						>
							Set to READY TO SUBMIT
						</b-button>
						<b-button 
							v-if="formPayload.status == 'ready' && checkPermission('update npwp request')"
							variant="primary" 
							@click="submitRequest()" 
						>
							Submit Request
						</b-button>
					</template>
				</ValidationObserver>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BTable,
	BPagination,
	BFormFile,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBTooltip
} from 'bootstrap-vue'
import _ from 'lodash'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Detail from '@/components/EFIN/Detail.vue'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BTable,
		BPagination,
		BFormFile,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
		Detail,
		vSelect,
		flatPickr,
		ValidationProvider,
		ValidationObserver
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			checkPermission,
			errorNotification,
			successNotification,
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no' ,'name', 'email', 'phone_number', 'waktu_pengajuan', 'status', 'actions'],
			npwp: [],
			link: 'ready',
			filter: {
				name: '',
				phone_number: '',
				email: '',
				created_at: ''
			},
			formPayload: Object,
			pages: [],
			displayInput: '',
			// uuid: '',
			photo_url: null,
			editId: null,
			myState : {
				pdf: null,
				currentPage: 1,
				zoom: 0.4
			},
			validations: '',
			countries: [],
			klu1s: [],
			klus: [],
			searchResultsDomisili: [],
			searchResultsKtp: [],
			loadingDomilisi: false,
			loadingKtp: false,
      		searchErrorDomisili: null,
      		searchErrorKtp: null,
      		disableWatcherDomisili: false,
      		disableWatcherKtp: false,
      		initialLoadDomisili: true,
      		initialLoadKtp: true
		}
	},
	computed: {
		rows() {
			return this.npwp.length
		},
		kluOptions() {
	    	return this.formPayload.klu_type === 'klu1' ? this.klu1s : this.klus;
	    }
	},
	mounted() {
		this.loadCountry()
	},
	watch: {
	    'formPayload.domisili_village': _.debounce(async function (newVal) {
	    	if (this.initialLoadDomisili) {
		        this.initialLoadDomisili = false;
		        return;
		    }

	    	if (this.disableWatcherDomisili) {
		        this.disableWatcherDomisili = false;
		        return;
		    }

	    	if (newVal.length > 2) {
		        this.loadingDomilisi = true;
		        this.searchErrorDomisili = null;
		        try {
		         	await this.searchVillages('domisili', newVal);
		        } catch (error) {
		         	this.searchErrorDomisili = 'Error fetching data';
		        }
		        this.loadingDomilisi = false;
		    } else {
		        this.searchResultsDomisili = [];
		    }
	    }, 300), // debounce delay
	    'formPayload.ktp_village': _.debounce(async function (newVal) {
	    	if (this.initialLoadKtp) {
		        this.initialLoadKtp = false;
		        return;
		    }

	    	if (this.disableWatcherKtp) {
		        this.disableWatcherKtp = false;
		        return;
		    }

	    	if (newVal.length > 2) {
		        this.loadingKtp = true;
		        this.searchErrorKtp = null;
		        try {
		         	await this.searchVillages('ktp', newVal);
		        } catch (error) {
		         	this.searchErrorKtp = 'Error fetching data';
		        }
		        this.loadingKtp = false;
		    } else {
		        this.searchResultsKtp = [];
		    }
	    }, 300), // debounce delay
	},
  	methods: {
		modalId(id) {
			return 'modal_' + id;
		},
		changeKluType(event) {
			if (event.target.value == 'klu1') {
				this.loadKlu1()
			} else {
				this.loadKlu()
			}
			this.formPayload.klu_code = null;
		},
		changePhotoKtp(event) {
			const file = event.target.files[0];
		    if (file) {
		        this.formPayload.fileKtp = URL.createObjectURL(file);
		        this.formPayload.fileToUploadKtp = file; // Store the file for upload
		    }
		},
		changePhotoPisahHarta(event) {
			const file = event.target.files[0];
		    if (file) {
		        this.formPayload.filePisahHarta = URL.createObjectURL(file);
		        this.formPayload.fileToUploadPisahHarta = file; // Store the file for upload
		    }
		},
		changePhotoAktaKawin(event) {
			const file = event.target.files[0];
		    if (file) {
		        this.formPayload.fileAktaKawin = URL.createObjectURL(file);
		        this.formPayload.fileToUploadAktaKawin = file; // Store the file for upload
		    }
		},
		changePhotoNpwpSuami(event) {
			const file = event.target.files[0];
		    if (file) {
		        this.formPayload.fileNpwpSuami = URL.createObjectURL(file);
		        this.formPayload.fileToUploadNpwpSuami = file; // Store the file for upload
		    }
		},
		changePhotoMemilihTerpisah(event) {
			const file = event.target.files[0];
		    if (file) {
		        this.formPayload.fileMemilihTerpisah = URL.createObjectURL(file);
		        this.formPayload.fileToUploadMemilihTerpisah = file; // Store the file for upload
		    }
		},
		changePhotoKitas(event) {
			const file = event.target.files[0];
		    if (file) {
		        this.formPayload.fileKitas = URL.createObjectURL(file);
		        this.formPayload.fileToUploadKitas = file; // Store the file for upload
		    }
		},
		cleanUpForm() {
			this.editId = null,
			this.validations = '',
			this.formPayload = Object
		},
		preparePayload() {
			const form = new FormData()
			this.formPayload.country_code.code != null ? this.formPayload.country_code = this.formPayload.country_code.code : ''
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] !== null) {
					if (key != 'fileKtp' && 
						key != 'filePisahHarta' &&
						key != 'fileAktaKawin' &&
						key != 'fileNpwpSuami' &&
						key != 'fileMemilihTerpisah' &&
						key != 'fileKitas') {
						form.append(key, this.formPayload[key])
					}
				}
			}

			return form
		},
		detailItem(item) {
			this.cleanUpForm();
			this.isLoading = true
			this.editId = item.uuid
			this.$http.get('admin/npwp/detail/' + this.editId)
			.then(response => {
				const data = response.data.data;
				this.formPayload = {
					status					: data.status,
					name					: data.name,
					email					: data.email,
					gender 					: data.gender,
					phone_number			: data.phone_number,
					nik 					: data.nik,
					family_card_number		: data.family_card_number,
					marital_status			: data.marital_status,
					birth_date 				: data.birth_date,
					birth_place 			: data.birth_place,
					country_code			: data.country_code,
					passpor_number			: data.passpor_number,
					kitas_number			: data.kitas_number,
					taxpayer_center_status	: data.taxpayer_center_status,
					taxpayer_center_npwp	: data.taxpayer_center_npwp,
					income_range_code		: data.income_range_code,
					klu_type				: data.klu_informations?.[0]?.klu_type || '',
					klu_merk				: data.klu_informations?.[0]?.klu_merk || '',
					klu_code				: data.klu_informations?.[0]?.klu_code || '',
					klu_description			: data.klu_informations?.[0]?.klu_description || '',
					klu_pembukuan			: data.klu_informations?.[0]?.klu_pembukuan || '',
					klu_have_pegawai		: data.klu_informations?.[0]?.klu_have_pegawai || '',
					klu_thn_buku_awal		: data.klu_informations?.[0]?.klu_thn_buku_awal || '',
					klu_thn_buku_akhir		: data.klu_informations?.[0]?.klu_thn_buku_akhir || '',
					domisili_street			: data.addresses.domisili ? data.addresses.domisili.street : '',
					domisili_blok			: data.addresses.domisili ? data.addresses.domisili.blok : '',
					domisili_number			: data.addresses.domisili ? data.addresses.domisili.number : '',
					domisili_rt				: data.addresses.domisili ? data.addresses.domisili.rt : '',
					domisili_rw				: data.addresses.domisili ? data.addresses.domisili.rw : '',
					domisili_faksimili_code	: data.addresses.domisili ? data.addresses.domisili.faksimili_code : '',
					domisili_postal_code	: data.addresses.domisili ? data.addresses.domisili.postal_code : '',
					domisili_region_code	: data.addresses.domisili ? data.addresses.domisili.region_code : '',
					domisili_village		: data.addresses.domisili ? data.addresses.domisili.village : '',
					domisili_subdistrict	: data.addresses.domisili ? data.addresses.domisili.subdistrict : '',
					domisili_city			: data.addresses.domisili ? data.addresses.domisili.city : '',
					domisili_province		: data.addresses.domisili ? data.addresses.domisili.province : '',
					domisili_phone_number	: data.addresses.domisili ? data.addresses.domisili.phone_number : '',
					domisili_telp_number	: data.addresses.domisili ? data.addresses.domisili.telp_number : '',
					ktp_street				: data.addresses.ktp ? data.addresses.ktp.street : '',
					ktp_blok				: data.addresses.ktp ? data.addresses.ktp.blok : '',
					ktp_number				: data.addresses.ktp ? data.addresses.ktp.number : '',
					ktp_rt					: data.addresses.ktp ? data.addresses.ktp.rt : '',
					ktp_rw					: data.addresses.ktp ? data.addresses.ktp.rw : '',
					ktp_faksimili_code		: data.addresses.ktp ? data.addresses.ktp.faksimili_code : '',
					ktp_postal_code			: data.addresses.ktp ? data.addresses.ktp.postal_code : '',
					ktp_region_code			: data.addresses.ktp ? data.addresses.ktp.region_code : '',
					ktp_village				: data.addresses.ktp ? data.addresses.ktp.village : '',
					ktp_subdistrict			: data.addresses.ktp ? data.addresses.ktp.subdistrict : '',
					ktp_city				: data.addresses.ktp ? data.addresses.ktp.city : '',
					ktp_province			: data.addresses.ktp ? data.addresses.ktp.province : '',
					ktp_phone_number		: data.addresses.ktp ? data.addresses.ktp.phone_number : '',
					ktp_telp_number			: data.addresses.ktp ? data.addresses.ktp.telp_number : '',
					fileKtp					: data.files.ktp,
					filePisahHarta			: data.files.pisah_harta,
					fileAktaKawin			: data.files.akta_kawin,
					fileNpwpSuami			: data.files.npwp_suami,
					fileMemilihTerpisah		: data.files.memilih_terpisah,
					fileKitas				: data.files.kitas,
					fileToUploadKtp			: null,
					fileToUploadPisahHarta	: null,
					fileToUploadNpwpSuami	: null,
					fileToUploadAktaKawin	: null,
					fileToUploadMemilihTerpisah	: null,
					fileToUploadKitas		: null,
				}
			}),
			this.isLoading = false
			this.initialLoadDomisili = true;
			this.initialLoadKtp = true;
			this.loadKlu1();
			this.loadKlu();
			this.$bvModal.show('form-npwp-modal')
		},
		render() {
            this.myState.pdf.getPage(this.myState.currentPage).then((page) => {
                var canvas = document.getElementById("pdf_renderer");
                var ctx = canvas.getContext('2d');
                var viewport = page.getViewport(this.myState.zoom);

                canvas.width = viewport.width;
                canvas.height = viewport.height;

                page.render({
                    canvasContext: ctx,
                    viewport: viewport
                });
            });
        },
		updateToReady() {
			var form = this.preparePayload();
			this.isLoading = true
			this.$http.post(`admin/npwp/set-to-ready/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-npwp-modal')
				this.getData()
				successNotification(this, 'Success', 'Berhasil mengubah status!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages || error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				} 
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					// errorNotification(this, 'Oops', this.validations.name.join(' '))       
				}
				this.isLoading = false
			})
		},
		updateForm() {
			var form = this.preparePayload();
			form.append("_method", 'PUT')
			this.isLoading = true
			this.$http.post(`admin/npwp/update/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-npwp-modal')
				this.getData()
				successNotification(this, 'Success', 'Berhasil mengubah form!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages) {
					this.validations = error.response.data.meta.messages
					errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				}
				this.isLoading = false
			})
		},
		submitRequest() {
			var form = this.preparePayload();
			this.isLoading = true
			this.$http.post(`admin/npwp/submit-request/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-npwp-modal')
				this.getData()
				successNotification(this, 'Success', 'Berhasil submit request!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages) {
					this.validations = error.response.data.meta.messages
					errorNotification(this, 'Oops!', error.response.data.meta.messages)       
				}
				this.isLoading = false
			})
		},
		async loadRegion() {
			this.$http.get('admin/npwp/regions')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.regions.push({
						'city': element.city,
						'postal_code': element.postal_code,
						'province': element.province,
						'region_code': element.region_code,
						'subdistrict': element.subdistrict,
						'village': element.village
					})
				});
			})
		},
		async searchVillages(type, query) {
			try {
				const response = await this.$http.get(`admin/npwp/regions?village=${query}`);
					console.log(type)
				if (type == 'domisili') {
					this.searchResultsDomisili = response.data.data;
				} else {
					this.searchResultsKtp = response.data.data;
				}
			} catch (error) {
				console.error('Error fetching data:', error);
				throw error;
			}
	    },
	    selectVillage(type, result) {
	    	if (type == 'domisili') {
	    		this.disableWatcherDomisili = true;
				this.formPayload.domisili_village = result.village;
				this.formPayload.domisili_subdistrict = result.subdistrict;
				this.formPayload.domisili_city = result.city;
				this.formPayload.domisili_province = result.province;
				this.formPayload.domisili_region_code = result.region_code;
				this.searchResultsDomisili = [];
	    	} else {
	    		this.disableWatcherKtp = true;
				this.formPayload.ktp_village = result.village;
				this.formPayload.ktp_subdistrict = result.subdistrict;
				this.formPayload.ktp_city = result.city;
				this.formPayload.ktp_province = result.province;
				this.formPayload.ktp_region_code = result.region_code;
				this.searchResultsKtp = [];
	    	}
	    },
		async loadCountry() {
			this.$http.get('admin/countries')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.countries.push({
						'code': element.code,
						'name': element.name
					})
				});
			})
		},
		loadKlu1() {
			this.klu1s = []
			this.$http.get('reference/npwp-klu?klu_type=klu1')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.klu1s.push({
						'klu_code': element.klu_code,
						'name': element.name
					})
				});
			})
		},
		loadKlu() {
			this.klus = []
			this.$http.get('reference/npwp-klu?klu_type=klu2')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.klus.push({
						'klu_code': element.klu_code,
						'name': element.name
					})
				});
			})
		},
		getData(page) { //page = 1
			this.isLoading = true
			let url = this.link
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/npwp/' + url ,
			{
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.npwp = response.data.data.data
				this.isLoading = false
			})
		},
		SetToPending(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Untuk merubah status NPWP Request menjadi Pending?',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Set to Pending',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.post('admin/npwp/set-to-pending/' + item)
					this.getData()
					successNotification(this, 'Success', 'Status NPWP Request berhasil diubah!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getData();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
	.form-group {
	  position: relative;
	}

	.dropdown-container {
	  position: absolute;
	  width: 150%;
	  z-index: 1000; /* Ensure dropdown appears above other content */
	}

	.list-group {
	  margin: 0; /* Remove margin if needed */
	  padding: 0; /* Remove padding if needed */
	}

	.list-group-item {
	  width: 100%; /* Ensure list item takes full width */
	  box-sizing: border-box; /* Include padding and border in the element's total width and height */
	}

	.full-width-item {
	  display: block; /* Ensure list item is displayed as a block element */
	}
</style>