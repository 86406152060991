<template>
	<b-card title="List Tax Client">
		<b-row class="mb-2">
			<b-col cols="12" md="6">
				<b-button
					@click="newForm()" 
					v-b-modal.form-user-modal
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Create Tax Client
				</b-button>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover 
			:items="taxClients"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ index + 1 }}
			</template>

			<template v-slot:cell(is_active)="{ item }">
				<span
					class="badge badge-pill badge-success"
					v-if="item.is_active === 1"
				>
					Aktif
				</span>
				<span
					class="badge badge-pill badge-danger"
					v-if="item.is_active === 0"
				>
					Tidak Aktif
				</span>
			</template>

			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<b-badge 
						variant="warning"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.id}-edit-icon`"
							icon="EditIcon"
							class="cursor-pointer"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Edit Data'"
							@click="editItem(item)"
							v-if="checkPermission('update service')"
						/>
					</b-badge>

				</div>
			</template>
		</b-table>
		<b-modal
			id="form-user-modal"
			centered
			:title="formPayload.id != null ? 'Edit Tax Client' : 'Add Tax Client'"
			hide-footer
			no-close-on-backdrop
		>
			<ValidationObserver>
				<form @submit.prevent="submitItem(formPayload)">
					<div class="form-group">
						<label>Client Name</label>
						<validation-provider
							name="Client Name"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.client_name"
							>
							<span
								v-for="(validation, index) in validations.client_name"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>API Key</label>
						<validation-provider
							name="API Key"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.api_key"
								disabled
							>
							<span
								v-for="(validation, index) in validations.api_key"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>API Secret</label>
						<validation-provider
							name="API Secret"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.api_secret"
								disabled
							>
							<span
								v-for="(validation, index) in validations.api_secret"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Admin Fee</label>
						<validation-provider
							name="Admin Fee"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPayload.admin_fee"
							>
							<span
								v-for="(validation, index) in validations.admin_fee"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Status</label>
						<select 
							class="form-control"
							v-model="formPayload.is_active"
						>
							<option value="">-- Pilih Status --</option>
							<option value=1>Aktif</option>
							<option value=0>Tidak Aktif</option>
						</select>
					</div>
					<button v-if="formPayload.id == null" 
						variant="primary" 
						class="btn waves-effect waves-float waves-light btn-primary mt-2">
						Create
					</button>
					<button v-else
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary mt-2">
						Save
					</button>
				</form>
			</ValidationObserver>
		</b-modal>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import _ from 'lodash'
import { 
	BCard,
	BBadge,
	BRow,
	BCol,
	BCardText,
	BTable,
	BPagination,
	BSpinner,
	VBModal,
	VBTooltip
} from 'bootstrap-vue'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BBadge,
		BRow,
		BCol,
		BCardText,
		BTable,
		BPagination,
		BSpinner,
		ValidationProvider, 
		ValidationObserver,
	},

	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			// result:{},
			isLoading: false,
			fields: [
		        { key: 'no', label: 'No.' },
		        { key: 'client_name', label: 'Client Name' },
		        { key: 'admin_fee', label: 'Admin Fee' },
		        { key: 'is_active', label: 'Status' },
		        { key: 'actions', label: 'Actions' },
		    ],
			taxClients: [],
			filter: {
				keyword: ''
			},
			formService: {
				status:''
			},
			formPayload: Object,
			validations: ''
		}
	},

	computed: {
		rows() {
			return this.taxClients.length
		}
	},

	methods: {

		generateUUID() {
			return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
				(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
			);
	    },

		cleanUpForm() {
			this.validations = ''
		},

		newForm() {
			this.formPayload = {
				id: '',
				client_name: '',
				api_key: this.generateUUID(),
				api_secret: this.generateUUID(),
				admin_fee: '',
				is_active: '',
			}
		},

		getData(page) {
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/tax-clients', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.taxClients = response.data.data
				this.isLoading = false
			})
		},

		preparePayload() {
			const form = new FormData()
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {	
					form.append(key, this.formPayload[key])
				}
			}
			return form;
		},

		submitItem() {
			if (this.formPayload.id === "") {
				this.createItem(this.formPayload);
			} else {
				this.updateItem(this.formPayload);
			}
	    },

		createItem(item) {
			this.$http.post('admin/tax-clients/', item)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Tax Client Berhasil Ditambahkan!')
				this.$bvModal.hide('form-user-modal')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Client Name', this.validations.client_name.join(' '))
					errorNotification(this, 'API Key', this.validations.api_key.join(' '))
					errorNotification(this, 'API Secret', this.validations.api_secret.join(' '))
					errorNotification(this, 'Admin Fee', this.validations.admin_fee.join(' '))
					errorNotification(this, 'Status', this.validations.is_active.join(' '))
				}
			})
		},

		updateItem(item) {
			item._method = 'PATCH'
			this.$http.post('admin/tax-clients/' + item.id, item)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Tax Client Berhasil Diedit!')
				this.$bvModal.hide('form-user-modal')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Client Name', this.validations.client_name.join(' '))
					errorNotification(this, 'API Key', this.validations.api_key.join(' '))
					errorNotification(this, 'API Secret', this.validations.api_secret.join(' '))
					errorNotification(this, 'Admin Fee', this.validations.admin_fee.join(' '))
					errorNotification(this, 'Status', this.validations.is_active.join(' '))
				}
			})
		},

		editItem(item) {
			this.cleanUpForm();
			this.formPayload = {
				id: item.id,
				client_name: item.client_name,
				api_key: item.api_key,
				api_secret: item.api_secret,
				admin_fee: item.admin_fee,
				is_active: item.is_active,
			}
			this.$bvModal.show('form-user-modal')
		},
	},

	created() {
		this.getData()
	}
}
</script>