import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        auth: true,
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/CustomerList/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Customers',
        breadcrumb: [
          {
            text: 'Customers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers-detail/:uuid',
      name: 'customers-detail',
      component: () => import('@/views/CustomerList/Detail.vue'),
      meta: {
        auth: true,
        pageTitle: 'Customers Detail',
        navActiveLink: 'customers',
        breadcrumb: [
          {
            text: 'Customers Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activity-log',
      name: 'activity-log',
      component: () => import('@/views/ActivityLog/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Activity Log',
        breadcrumb: [
          {
            text: 'Activity Log',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transaction',
      name: 'transaction',
      component: () => import('@/views/Transaction/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Transaction List',
        breadcrumb: [
          {
            text: 'Transaction List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transaction-detail/:link/:uuid',
      name: 'transaction-detail',
      component: () => import('@/views/Transaction/Detail.vue'),
      meta: {
        auth: true,
        pageTitle: 'Transaction Detail',
        navActiveLink: 'transaction',
        breadcrumb: [
          {
            text: 'Transaction Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/efin',
      name: 'efin',
      component: () => import('@/views/Efin.vue'),
      meta: {
        auth: true,
        pageTitle: 'EFIN',
        breadcrumb: [
          {
            text: 'EFIN',
            active: true,
          },
        ],
      },
    },
    {
      path: '/npwp-request',
      name: 'npwp-request',
      component: () => import('@/views/NpwpRequest.vue'),
      meta: {
        auth: true,
        pageTitle: 'NPWP Request',
        breadcrumb: [
          {
            text: 'NPWP Request',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tax-recomendation',
      name: 'tax-recomendation',
      component: () => import('@/views/RekomendasiPajak/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Rekomendasi Pajak',
        breadcrumb: [
          {
            text: 'Rekomendasi Pajak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tax-recomendation-helper/:uuid',
      name: 'tax-recomendation-helper',
      component: () => import('@/views/RekomendasiPajak/Detail.vue'),
      meta: {
        auth: true,
        pageTitle: 'Bantuan Rekomendasi Pajak',
        navActiveLink: 'tax-recomendation',
        breadcrumb: [
          {
            text: 'Bantuan Rekomendasi Pajak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-list',
      name: 'product-list',
      component: () => import('@/views/SettingSPTTahunan/ProductList/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Product List',
        breadcrumb: [
          {
            text: 'Product List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-product-list',
      name: 'create-product-list',
      component: () => import('@/views/SettingSPTTahunan/ProductList/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Product List',
        navActiveLink: 'product-list',
        breadcrumb: [
          {
            text: 'Create Product List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-product-list/:uuid',
      name: 'edit-product-list',
      component: () => import('@/views/SettingSPTTahunan/ProductList/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Product List',
        navActiveLink: 'product-list',
        breadcrumb: [
          {
            text: 'Product List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-menu',
      name: 'product-menu',
      component: () => import('@/views/SettingSPTTahunan/ProductMenu.vue'),
      meta: {
        auth: true,
        pageTitle: 'Product Menu',
        breadcrumb: [
          {
            text: 'Product Menu',
            active: true,
          },
        ],
      },
    },
    {
      path: '/coupon-list',
      name: 'coupon-list',
      component: () => import('@/views/SettingSPTTahunan/CouponList/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Coupon List',
        breadcrumb: [
          {
            text: 'Coupon List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-coupon-list',
      name: 'create-coupon-list',
      component: () => import('@/views/SettingSPTTahunan/CouponList/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Generate Coupon List',
        navActiveLink: 'coupon-list',
        breadcrumb: [
          {
            text: 'Generate Coupon List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-coupon-list/:uuid',
      name: 'edit-coupon-list',
      component: () => import('@/views/SettingSPTTahunan/CouponList/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Coupon List',
        navActiveLink: 'coupon-list',
        breadcrumb: [
          {
            text: 'Edit Coupon List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/consultation-price-list',
      name: 'consultation-price-list',
      component: () => import('@/views/SettingSPTTahunan/ProductConsultation.vue'),
      meta: {
        auth: true,
        pageTitle: 'Consultation Price List',
        breadcrumb: [
          {
            text: 'Consultation Price List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/jenis-pekerjaan',
      name: 'jenis-pekerjaan',
      component: () => import('@/views/SettingSPTTahunan/JenisPekerjaan.vue'),
      meta: {
        auth: true,
        pageTitle: 'Jenis Pekerjaan',
        breadcrumb: [
          {
            text: 'Jenis Pekerjaan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/group-jenis-pekerjaan',
      name: 'group-jenis-pekerjaan',
      component: () => import('@/views/SettingSPTTahunan/GroupJenisPekerjaan.vue'),
      meta: {
        auth: true,
        pageTitle: 'Grup Jenis Pekerjaan',
        breadcrumb: [
          {
            text: 'Grup Jenis Pekerjaan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/import-jenis-pekerjaan',
      name: 'import-jenis-pekerjaan',
      component: () => import('@/views/SettingSPTTahunan/JenisPekerjaan/import.vue'),
      meta: {
        auth: true,
        pageTitle: 'Import Jenis Pekerjaan',
        breadcrumb: [
          {
            text: 'Import Jenis Pekerjaan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/progressive',
      name: 'progressive',
      component: () => import('@/views/SettingSPTTahunan/Progressive/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Progressive',
        breadcrumb: [
          {
            text: 'Progressive',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-progressive',
      name: 'create-progressive',
      component: () => import('@/views/SettingSPTTahunan/Progressive/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Progressive',
        navActiveLink: 'progressive',
        breadcrumb: [
          {
            text: 'Create Progressive',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-progressive/:uuid',
      name: 'edit-progressive',
      component: () => import('@/views/SettingSPTTahunan/Progressive/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Update Progressive',
        navActiveLink: 'progressive',
        breadcrumb: [
          {
            text: 'Update Progressive',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ptkp',
      name: 'ptkp',
      component: () => import('@/views/SettingSPTTahunan/PTKP/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Penghasilan Tidak Kena Pajak',
        breadcrumb: [
          {
            text: 'Penghasilan Tidak Kena Pajak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-ptkp',
      name: 'create-ptkp',
      component: () => import('@/views/SettingSPTTahunan/PTKP/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create PTKP',
        navActiveLink: 'ptkp',
        breadcrumb: [
          {
            text: 'Create PTKP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-ptkp/:uuid',
      name: 'edit-ptkp',
      component: () => import('@/views/SettingSPTTahunan/PTKP/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit PTKP',
        navActiveLink: 'ptkp',
        breadcrumb: [
          {
            text: 'Edit PTKP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/setting-pph23',
      name: 'setting-pph23',
      component: () => import('@/views/SettingSPTTahunan/Settingpph23.vue'),
      meta: {
        auth: true,
        pageTitle: 'Setting PPh23',
        breadcrumb: [
          {
            text: 'Setting PPh23',
            active: true,
          },
        ],
      },
    },
    {
      path: '/omset-setting',
      name: 'omset-setting',
      component: () => import('@/views/SettingSPTTahunan/OmsetSetting.vue'),
      meta: {
        auth: true,
        pageTitle: 'Omset Setting',
        breadcrumb: [
          {
            text: 'Omset Setting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/spt',
      name: 'spt',
      component: () => import('@/views/SettingSPTTahunan/SPT.vue'),
      meta: {
        auth: true,
        pageTitle: 'SPT',
        breadcrumb: [
          {
            text: 'SPT',
            active: true,
          },
        ],
      },
    },
    {
      path: '/compliting-profile',
      name: 'compliting-profile',
      component: () => import('@/views/SettingSPTTahunan/ComplitingProfile/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Rekomendasi Pajak',
        breadcrumb: [
          {
            text: 'Rekomendasi Pajak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/biaya-platform',
      name: 'biaya-platform',
      component: () => import('@/views/SettingSPTTahunan/BiayaPkb/BiayaPlatform.vue'),
      meta: {
        auth: true,
        pageTitle: 'Biaya PKB',
        breadcrumb: [
          {
            text: 'Biaya PKB',
            active: true,
          },
        ],
      },
    },
    {
      path: '/biaya-admin',
      name: 'biaya-admin',
      component: () => import('@/views/SettingSPTTahunan/BiayaPkb/BiayaAdmin.vue'),
      meta: {
        auth: true,
        pageTitle: 'Biaya Admin',
        breadcrumb: [
          {
            text: 'Biaya Admin',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-compliting-profile/:uuid',
      name: 'edit-compliting-profile',
      component: () => import('@/views/SettingSPTTahunan/ComplitingProfile/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Rekomendasi Pajak',
        navActiveLink: 'compliting-profile',
        breadcrumb: [
          {
            text: 'Edit Rekomendasi Pajak',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/data-customer',
    //   name: 'data-customer',
    //   component: () => import('@/views/SettingSPTTahunan/DataCustomer.vue'),
    //   meta: {
    //     auth: true,
    //     pageTitle: 'Data Customer',
    //     breadcrumb: [
    //       {
    //         text: 'Data Customer',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/banner-setting',
      name: 'banner-setting',
      component: () => import('@/views/ApplicationSetting/BannerSetting.vue'),
      meta: {
        auth: true,
        pageTitle: 'Banner Settings',
        breadcrumb: [
          {
            text: 'Banner Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner-your-feature',
      name: 'banner-your-feature',
      component: () => import('@/views/ApplicationSetting/BannerYourFeature/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Banner Fitur Kamu',
        breadcrumb: [
          {
            text: 'Banner Fitur Kamu',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-banner-your-feature',
      name: 'create-banner-your-feature',
      component: () => import('@/views/ApplicationSetting/BannerYourFeature/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Banner Fitur Kamu',
        navActiveLink: 'banner-your-feature',
        breadcrumb: [
          {
            text: 'Create Banner Fitur Kamu',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-banner-your-feature/:id',
      name: 'edit-banner-your-feature/:id',
      component: () => import('@/views/ApplicationSetting/BannerYourFeature/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Banner Fitur Kamu',
        navActiveLink: 'banner-your-feature',
        breadcrumb: [
          {
            text: 'Edit Banner Fitur Kamu',
            active: true,
          },
        ],
      },
    },
    {
      path: '/page',
      name: 'page',
      component: () => import('@/views/ApplicationSetting/Page/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Page',
        breadcrumb: [
          {
            text: 'Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-page',
      name: 'create-page',
      component: () => import('@/views/ApplicationSetting/Page/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Page',
        breadcrumb: [
          {
            text: 'Create Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-page/:uuid',
      name: 'edit-page',
      component: () => import('@/views/ApplicationSetting/Page/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            text: 'Edit Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/ApplicationSetting/About.vue'),
      meta: {
        auth: true,
        pageTitle: 'About hipajak',
        breadcrumb: [
          {
            text: 'About hipajak',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faq-category',
      name: 'faq-category',
      component: () => import('@/views/ApplicationSetting/FaqCategory.vue'),
      meta: {
        auth: true,
        pageTitle: 'FAQ Category',
        breadcrumb: [
          {
            text: 'FAQ Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faq-content',
      name: 'faq-content',
      component: () => import('@/views/ApplicationSetting/FaqContent/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'FAQ Content',
        breadcrumb: [
          {
            text: 'FAQ Content',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-faq-content',
      name: 'create-faq-content',
      component: () => import('@/views/ApplicationSetting/FaqContent/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create FAQ Content',
        navActiveLink: 'faq-content',
        breadcrumb: [
          {
            text: 'Create FAQ Content',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-faq-content/:uuid',
      name: 'edit-faq-content',
      component: () => import('@/views/ApplicationSetting/FaqContent/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit FAQ Content',
        navActiveLink: 'faq-content',
        breadcrumb: [
          {
            text: 'Edit FAQ Content',
            active: true,
          },
        ],
      },
    },
    {
      path: '/article',
      name: 'article',
      component: () => import('@/views/ApplicationSetting/Article/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Article List',
        breadcrumb: [
          {
            text: 'Article List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-article',
      name: 'create-article',
      component: () => import('@/views/ApplicationSetting/Article/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Article',
        navActiveLink: 'article',
        breadcrumb: [
          {
            text: 'Create Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-article/:uuid',
      name: 'edit-article',
      component: () => import('@/views/ApplicationSetting/Article/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Article',
        navActiveLink: 'article',
        breadcrumb: [
          {
            text: 'Edit Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/setting-app',
      name: 'setting-app',
      component: () => import('@/views/ApplicationSetting/SettingApp/Setting.vue'),
      meta: {
        auth: true,
        pageTitle: 'Setting App',
        breadcrumb: [
          {
            text: 'Setting App',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tax-client',
      name: 'tax-client',
      component: () => import('@/views/ApplicationSetting/TaxClient.vue'),
      meta: {
        auth: true,
        pageTitle: 'Tax Client',
        breadcrumb: [
          {
            text: 'Tax Client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/guide-efin',
      name: 'guide-efin',
      component: () => import('@/views/ApplicationSetting/SettingApp/ListGuideApp.vue'),
      meta: {
        auth: true,
        pageTitle: 'Setting App Guide',
        breadcrumb: [
          {
            text: 'Setting App Guide',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kebijakan-dan-privasi',
      name: 'kebijakan-dan-privasi',
      component: () => import('@/views/ApplicationSetting/KebijakanPrivasi.vue'),
      meta: {
        auth: true,
        pageTitle: 'Kebijakan dan Privasi',
        breadcrumb: [
          {
            text: 'Kebijakan dan Privasi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/syarat-dan-ketentuan',
      name: 'syarat-dan-ketentuan',
      component: () => import('@/views/ApplicationSetting/SyaratKetentuan.vue'),
      meta: {
        auth: true,
        pageTitle: 'Syarat dan Ketentuan',
        breadcrumb: [
          {
            text: 'Syarat dan Ketentuan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/app-version',
      name: 'app-version',
      component: () => import('@/views/ApplicationSetting/AppVersionSetting.vue'),
      meta: {
        auth: true,
        pageTitle: 'App Version Setting',
        breadcrumb: [
          {
            text: 'App Version Setting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/ApplicationSetting/Services.vue'),
      meta: {
        auth: true,
        pageTitle: 'Services',
        breadcrumb: [
          {
            text: 'Services',
            active: true,
          },
        ],
      },
    },
    {
      path: '/custom-services',
      name: 'custom-services',
      component: () => import('@/views/ApplicationSetting/CustomServices.vue'),
      meta: {
        auth: true,
        pageTitle: 'Custom Services',
        breadcrumb: [
          {
            text: 'Custom Services',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bundling-service',
      name: 'bundling-service',
      component: () => import('@/views/ApplicationSetting/Bundling/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Bundling Service',
        breadcrumb: [
          {
            text: 'Bundling Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-bundling-service',
      name: 'create-bunding-service',
      component: () => import('@/views/ApplicationSetting/Bundling/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Bundling Service',
        navActiveLink: 'bundling-service',
        breadcrumb: [
          {
            text: 'Create Bundling Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-bundling-service/:id',
      name: 'edit-bundling-service/:id',
      component: () => import('@/views/ApplicationSetting/Bundling/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Bundling Service',
        navActiveLink: 'bundling-service',
        breadcrumb: [
          {
            text: 'Edit Bundling Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rekomendasi-service',
      name: 'rekomendasi-service',
      component: () => import('@/views/ApplicationSetting/Rekomendasi/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Rekomendasi Service',
        breadcrumb: [
          {
            text: 'Rekomendasi Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-rekomendasi-service/:id',
      name: 'edit-rekomendasi-service/:id',
      component: () => import('@/views/ApplicationSetting/Rekomendasi/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Rekomendasi Service',
        navActiveLink: 'rekomendasi-service',
        breadcrumb: [
          {
            text: 'Edit Rekomendasi Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-rekomendasi-service',
      name: 'create-rekomendasi-service',
      component: () => import('@/views/ApplicationSetting/Rekomendasi/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Rekomendasi Service',
        navActiveLink: 'rekomendasi-service',
        breadcrumb: [
          {
            text: 'Create Rekomendasi Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-user',
      name: 'admin-user',
      component: () => import('@/views/Admin/Index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Admin',
        breadcrumb: [
          {
            text: 'Admin',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-admin-user',
      name: 'create-admin-user',
      component: () => import('@/views/Admin/Create.vue'),
      meta: {
        auth: true,
        pageTitle: 'Create Admin User',
        navActiveLink: 'admin-user',
        breadcrumb: [
          {
            text: 'Create Admin User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-admin-user/:uuid',
      name: 'edit-admin-user',
      component: () => import('@/views/Admin/Edit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Edit Admin User',
        navActiveLink: 'admin-user',
        breadcrumb: [
          {
            text: 'Edit Admin User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        guest: true,
        layout: 'full',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        auth: true,
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect if not authenticated
  if (to.matched.some(record => record.meta.auth)) {
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // Redirect if authenticated
  if (to.matched.some(record => record.meta.guest) && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }
})

export default router
