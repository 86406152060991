export default [
	{
		header: 'Application Setting'
	},
	{
		title: 'Page',
		icon: 'MonitorIcon',
		route: 'page'
	},
	{
		title: 'Article',
		icon: 'SidebarIcon',
		route: 'article',
		permission: 'list article'
	},
	{
		title: 'Banner',
		icon: 'ImageIcon',
		children: [
			{
				title: 'Banner Settings',
				route: 'banner-setting',
				permission: 'list banner'
			},
			{
				title: 'Banner Fitur Kamu',
				route: 'banner-your-feature',
				permission: 'list banner'
			},
		]
	},
	{
		title: 'Frequently Asked Question',
		icon: 'HelpCircleIcon',
		children: [
			{
				title: 'FAQ Category',
				route: 'faq-category',
				permission: 'list faq'
			},
			{
				title: 'FAQ Content',
				route: 'faq-content',
				permission: 'list faq'
			},
		]
	},
	{
		title: 'Services',
		icon: 'SettingsIcon',
		children: [
			{
				title: 'List Service',
				route: 'services',
				permission: 'list service'
			},
			{
				title: 'List Custom Service',
				route: 'custom-services',
				permission: 'list service'
			},
			{
				title: 'Bundling Service',
				route: 'bundling-service',
				permission: 'list bundling feature'
			},
			{
				title: 'Rekomendasi Service',
				route: 'rekomendasi-service',
				permission: 'list recomendation feature'
			}
		]
	},
	// {
	// 	title: 'Setting App',
	// 	icon: 'SettingsIcon',
	// 	route: 'setting-app'
	// },
	{
		title: 'Setting App',
		icon: 'SettingsIcon',
		children: [
			{
				title: 'List Setting',
				route: 'setting-app',
			},
			// {
			// 	title: 'Guide App Link',
			// 	route: 'guide-efin',
			// },
		]
	},
	{
		title: 'Tax Client',
		icon: 'UsersIcon',
		route: 'tax-client',
	},
]