var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"List Tax Client"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.form-user-modal",modifiers:{"form-user-modal":true}}],staticClass:"btn waves-effect waves-float waves-light btn-primary",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.newForm()}}},[_vm._v(" Create Tax Client ")])],1)],1),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.taxClients,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(is_active)",fn:function(ref){
var item = ref.item;
return [(item.is_active === 1)?_c('span',{staticClass:"badge badge-pill badge-success"},[_vm._v(" Aktif ")]):_vm._e(),(item.is_active === 0)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" Tidak Aktif ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"warning"}},[(_vm.checkPermission('update service'))?_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Edit Data'),expression:"'Edit Data'",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (item.id) + "-edit-icon"),"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.editItem(item)}}}):_vm._e()],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"form-user-modal","centered":"","title":_vm.formPayload.id != null ? 'Edit Tax Client' : 'Add Tax Client',"hide-footer":"","no-close-on-backdrop":""}},[_c('ValidationObserver',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitItem(_vm.formPayload)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Client Name")]),_c('validation-provider',{attrs:{"name":"Client Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.client_name),expression:"formPayload.client_name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formPayload.client_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "client_name", $event.target.value)}}}),_vm._l((_vm.validations.client_name),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("API Key")]),_c('validation-provider',{attrs:{"name":"API Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.api_key),expression:"formPayload.api_key"}],staticClass:"form-control",class:classes,attrs:{"type":"text","state":errors.length > 0 ? false:null,"disabled":""},domProps:{"value":(_vm.formPayload.api_key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "api_key", $event.target.value)}}}),_vm._l((_vm.validations.api_key),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("API Secret")]),_c('validation-provider',{attrs:{"name":"API Secret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.api_secret),expression:"formPayload.api_secret"}],staticClass:"form-control",class:classes,attrs:{"type":"text","state":errors.length > 0 ? false:null,"disabled":""},domProps:{"value":(_vm.formPayload.api_secret)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "api_secret", $event.target.value)}}}),_vm._l((_vm.validations.api_secret),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Admin Fee")]),_c('validation-provider',{attrs:{"name":"Admin Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.admin_fee),expression:"formPayload.admin_fee"}],staticClass:"form-control",class:classes,attrs:{"type":"text","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formPayload.admin_fee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "admin_fee", $event.target.value)}}}),_vm._l((_vm.validations.admin_fee),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Status")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.is_active),expression:"formPayload.is_active"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formPayload, "is_active", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("-- Pilih Status --")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Aktif")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Tidak Aktif")])])]),(_vm.formPayload.id == null)?_c('button',{staticClass:"btn waves-effect waves-float waves-light btn-primary mt-2",attrs:{"variant":"primary"}},[_vm._v(" Create ")]):_c('button',{staticClass:"btn waves-effect waves-float waves-light btn-primary mt-2",attrs:{"variant":"primary"}},[_vm._v(" Save ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }